import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SEO from 'components/seo'
import { withMainLayout } from 'components/layouts'
import { fetchBlogArticles } from 'components/blog/actions'
import { MarathonHeader, MarathonCard } from 'components/common'
import { HolidaySetBanner } from 'components/common'

import css from './style.module.scss'

const pageData = {slug: 'marathon', title: 'Конкурс'}

function MarathonPage({
  fetchBlogArticles,
}) {
  const [stateArticles, setArticles] = useState([])

  useEffect(() => {
    fetchBlogArticles(`/section/${pageData.slug}/articles/?has_text`)
      .then(result => {
        setArticles(result.results)
      })
  }, [])

  return (
    <div className={css.pageMarathon}>
      <SEO
        title={pageData.title}
      />
      <MarathonHeader />
      <div className='container'>
        <div className={css.content}>
          {
            stateArticles.map((item, index) => (
              <MarathonCard
                key={index}
                date={
                  (new Intl.DateTimeFormat('ru-RU', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })).format(new Date(item.date))
                }
                title={item.name}
                html={item.text}
              />
            ))
          }
        </div>
      </div>
      <HolidaySetBanner/>
    </div>
  )
}

export default connect(
  null,
  dispatch => ({
    fetchBlogArticles: bindActionCreators(fetchBlogArticles, dispatch),
  })
)(withMainLayout(MarathonPage))