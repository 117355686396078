import axios from 'axios'

import { API_BASE_URL } from 'src/config'

export function fetchBlogArticles(q) {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'get',
        url: `${API_BASE_URL}${q}`,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return Promise.resolve(response.data)
    } catch (e) {
      // console.error(e.response)

      return Promise.reject(e)
    }
  }
}

export function fetchArticle(q) {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'get',
        url: `${API_BASE_URL}${q}`,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return Promise.resolve(response.data)
    } catch (e) {
      // console.error(e.response)

      return Promise.reject(e)
    }
  }
}